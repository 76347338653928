<template>
    <div class="config-property">
        <div>
            Tipo de Rodapé utilizado:
        </div>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" name="TIPO_RODAPE" v-model="value.TIPO_RODAPE">
                </div>
                <div class="col-sm-11">
                    {{ tipo.label }} <br>
                    <img :src="tipo.img">
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  import IMG_FOOTER_LINKS_SOCIAIS from '../img/FOOTER_LINKS_SOCIAIS.png';
  import IMG_FOOTER_LOGO_COLUNAS_LINKS from '../img/FOOTER_LOGO_COLUNAS_LINKS.png';

  export default {
    name: "TIPO_RODAPE",
    props: [
      'value'
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Seções de rodapé a esquerda e links sociais a direita',
            value: 'FOOTER_LINKS_SOCIAIS',
            img: IMG_FOOTER_LINKS_SOCIAIS,
          },
          {
            label: 'Seções de rodapé em colunas e links sociais abaixo',
            value: 'FOOTER_LOGO_COLUNAS_LINKS',
            img: IMG_FOOTER_LOGO_COLUNAS_LINKS,
          },
        ]
      }
    }
  }
</script>